<template>
  <tr>
    <td>
      <p>{{ campaign.campaignName }}</p>
      <p class="help has-text-weight-normal">by {{ campaign.accountEmail }}</p>
    </td>
    <td class="is-hidden-mobile">
      {{ acceptedInvites.length }} / {{ invites.length }}
    </td>
    <td>
      <p>{{ formatDate(campaign.completedAt ? campaign.completedAt : campaign.submittedAt) }}</p>
      <p v-if="campaign.completedAt" class="help">submitted {{ formatDate(campaign.submittedAt) }}</p>
    </td>
    <td class="has-text-right">
      <router-link :to="{ name: 'AdminViewCampaign', params: { campaignId: campaign.id }}" class="button is-info">
        <span>View</span>
        <Icon icon="chevron-right" />
      </router-link>
    </td>
  </tr>
</template>

<script>
import { inject, computed } from 'vue'
import Icon from '@/components/Icon.vue'
import filters from '@/utils/filters'

export default {
  props: ['campaign', 'invites'],
  components: { Icon },
  setup(props) {
    const isPending = inject('isPending')

    const acceptedInvites = computed(() => {
      return props.invites.filter(invite => invite.acceptedAt)
    })

    const doDeleteCampaign = async () => {
      isPending.value = true
      const { deleteDoc: deleteCampaign } = useDocument('campaigns', props.campaign.id)
      await deleteCampaign()
      isPending.value = false
    }

    return {
      acceptedInvites,
      doDeleteCampaign,

      ...filters,
    }
  }
}
</script>

<style scoped>
.is-clickable:hover {
  background-color: #fafafa;
}
</style>