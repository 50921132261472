<template>
  <div v-if="campaigns && invites" >
    <nav class="navbar top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand is-justify-content-space-between" style="width: 100%">
        <div class="navbar-item">
          <h1 class="title mb-0">
            Dashboard
          </h1>
        </div>

        <div class="navbar-item has-text-weight-normal">
          <span class="has-text-weight-bold">{{ unshippedCampaigns.length }} active campaigns</span>&nbsp;need to be shipped
        </div>
      </div>
    </nav>

    <section v-if="unshippedCampaigns.length" class="section has-border">
      <h3 class="subtitle">
        Campaigns to Ship
      </h3>

      <div class="table-container">
        <table class="table  is-fullwidth">
          <thead class="has-background-light">
            <tr>
              <th>Campaign</th>
              <th class="is-hidden-mobile">Recipients</th>
              <th>Submitted</th>
              <th></th>
            </tr>
          </thead>
          <tbody style="white-space: nowrap;">
            <AdminCampaignRow
              v-for="campaign in unshippedCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
        </table>
      </div>
    </section>

    <section v-if="shippedCampaigns.length" class="section has-padding-bottom">
      <h3 class="subtitle">
        Shipped Campaigns
      </h3>

      <div class="table-container">
        <table class="table is-fullwidth">
          <thead class="has-background-light">
            <tr>
              <th>Campaign</th>
              <th class="is-hidden-mobile">Recipients</th>
              <th>Approved</th>
              <th />
            </tr>
          </thead>
          <tbody style="white-space: nowrap;">
            <AdminCampaignRow
              v-for="campaign in shippedCampaigns"
              :key="campaign.id"
              :campaign="campaign"
              :invites="getInvites(campaign.id) ?? []"
            />
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import { computed } from 'vue'
import getCollection from '@/composables/getCollection'
import AdminCampaignRow from '@/components/Admin/AdminCampaignRow.vue'
import filters from '@/utils/filters'

export default {
  components: { AdminCampaignRow },
  setup() {
    const { documents: campaigns } = getCollection('campaigns')
    const { documents: invites } = getCollection('invites')

    const unshippedCampaigns = computed(() => {
      return campaigns.value && campaigns.value
        .filter(campaign => campaign.submittedAt && !campaign.completedAt)
        .sort((a, b) => a.submittedAt - b.submittedAt)
    })

    const shippedCampaigns = computed(() => {
      return campaigns.value && campaigns.value
        .filter(campaign => campaign.completedAt)
        .sort((a, b) => b.completedAt - a.completedAt)
    })

    const getInvites = (campaignId) => {
      return invites.value && invites.value.filter(invite => invite.campaignId === campaignId)
    }

    return {
      campaigns,
      invites,

      unshippedCampaigns,
      shippedCampaigns,
      getInvites,
      ...filters
    }
  }
}
</script>